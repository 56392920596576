




















































































































































import { Component, Vue } from 'vue-property-decorator';
import UserSettingsModule from '@/store/modules/UserSettingsModule';

@Component
export default class AmazonSettingsPage extends Vue {
  emailuser = '';
  emaildomain = '';
  kindledomains: string[] = [
    'free.kindle.com',
    'kindle.com',
    'free.kindle.cn',
    'kindle.cn',
  ];

  get validateEmailUser() {
    return this.isUserValid();
  }
  isUserValid() {
    return (
      this.emailuser.length > 0 && this.emailuser.indexOf('@') === -1
    );
  }

  get showchargeswarning() {
    return this.emaildomain.indexOf('free') === -1;
  }

  mounted() {
    const kindleemailaddress = UserSettingsModule.amazonsettings!
      .KindleEmailAddress;
    if (kindleemailaddress) {
      this.emaildomain = kindleemailaddress.split('@')[1];
      this.emailuser = kindleemailaddress.split('@')[0];
    }
  }

  kindleemailaddress() {
    return `${this.emailuser}@${this.emaildomain}`;
  }

  formvalid() {
    return this.emailuser && this.emaildomain && this.isUserValid();
  }

  async saveSettings() {
    await UserSettingsModule.updateAmazonKindleEmailAddress(
      this.kindleemailaddress(),
    );
    await UserSettingsModule.loadSettingsData();
  }
}
